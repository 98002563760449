const ajaxCaches = {}

const setAjaxCache = (url, prm) => (ajaxCaches[url] = prm)

const getAjaxCache = url => ajaxCaches[url]

const ajaxList = ({ page, firebase, nocache, order = [], cacheAs = page }) => {
	return Promise.all([
		new Promise(resolveAjax => {
			const fb =
				getAjaxCache(cacheAs) && !nocache
					? getAjaxCache(cacheAs)
					: setAjaxCache(
							cacheAs,
							firebase
								.category(page)
								.where('archived', '==', false)
								.where('draft', '==', false)
								.orderBy(order[0] || 'name', order[1] || 'asc')
								.get()
					  )
			fb.then(snapshot => {
				let pageInfo = []
				snapshot.forEach(doc => {
					pageInfo.push({ ...doc.data() })
				})
				resolveAjax(pageInfo)
			}).catch(err => console.error(err))
		}),
		new Promise(resolve => {
			if (page === 'chapters') resolve()
			const fb =
				getAjaxCache('chapterList') && !nocache
					? getAjaxCache('chapterList')
					: setAjaxCache('chapterList', firebase.chapters().get())

			fb.then(chapters => {
				let chapterList = []
				chapters.forEach(chapter => {
					let data = chapter.data()
					chapterList[data.id] = data
				})
				resolve(chapterList)
			}).catch(err => console.error(err))
		})
	])
		.then(([pages, chapters]) => {
			if (page !== 'chapters') {
				pages = pages.map(v => {
					v.chapter = !isNaN(v.chapterId)
						? chapters[v.chapterId].name
						: 'Unknown'
					return v
				})
			}
			return pages
		})
		.catch(err => console.error(err))
}

const ajax = ({ page, firebase, id, nocache, field = 'name' }) => {
   
	return new Promise(resolve => {
		const fb =
			getAjaxCache(id) && !nocache
				? getAjaxCache(id)
				: setAjaxCache(
						id,
                  firebase
                     .category(page)
                     .where(field, '==', id)
                     .get()
                     .then(snapshot => {
                        //previously resolved/cached
                        let pageInfo = {}
                        const doc = snapshot.docs[0]
                        pageInfo = { ...doc.data(), docId: doc.id }
                        return firebase
                           .category(page, doc.id)
                           .collection('contents')
                           .get()
                           .then(docs => {
                              if (docs.empty) resolve(pageInfo)
                              const data = docs.docs[0].data()
                              pageInfo.contentsId = docs.docs[0].id
                              if (data.contents && data.contents.length) pageInfo.contents = data.contents
                              return pageInfo
                           })
                     })			
              )
              
      fb.then(pageInfo => resolve(pageInfo))
	}).catch(err => console.error(err))
}

const ajaxArray = ({ order = ['name', 'asc'], firebase, field, value }) => {
	const fb = getAjaxCache(field + value + order[0] + order[1])
		? getAjaxCache(field + value + order[0] + order[1])
		: setAjaxCache(
				field + value + order[0] + order[1],
				firebase.db
					.collection('pages')
					.where(field, 'array-contains', value)
					.orderBy(order[0], order[1])
					.get()
		  )
	return fb
}

const ajaxCache = (id, info) => setAjaxCache(id, Promise.resolve(info))

export { ajax, ajaxList, ajaxCache, ajaxArray }
export default ajax
