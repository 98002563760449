import React, { useState } from 'react'
import nl2br from './js/nl2br'

const Contents = props => {
	let {
		page,
		edit,
		form = 'contents',
		contents,
		contentsAlt,
		title = form
	} = props

	const onChangeContent = e => {
		let field = e.target.name.split('[')
		contentsAlt[field[1].split(']')[0]][field[0]] = e.target.value
		props.onContentChanged(form, contentsAlt)
	}

	const moreContent = e => {
		contentsAlt.push({ title: '', text: '' })
		props.onContentChanged(form, contentsAlt)
	}

	const deleteContent = i => {
		contentsAlt.splice(i, 1)
		props.onContentChanged(form, contentsAlt)
	}

	return (
		<div style={{ flex: 1 }}>
			{page && !edit ? (
				contents.map((content, i) => (
					<div key={i} className='mb-4'>
						{content.title && <h4>{content.title}</h4>}
						<div
							dangerouslySetInnerHTML={{ __html: nl2br(content.text) }}
						/>
					</div>
				))
			) : (
				<>
					<h4>{title || ''}</h4>
					{contentsAlt.map((content, i) => (
						<div
							key={i}
							className='mb-4'
							style={{
								flex: 1,
								display: 'flex',
								flexDirection: 'column',
								border: '1px solid #2d2d2d'
							}}
						>
							<div
								style={{
									flex: 1,
									display: 'flex',
									flexDirection: 'row'
								}}
							>
								<input
									type='text'
									name={`title[${i}]`}
									placeholder='Title'
									value={content.title}
									onChange={onChangeContent}
									style={{ flex: 1 }}
								/>
								<input
									type='button'
									value='X'
									onClick={() => deleteContent(i)}
									title='Delete section'
								/>
							</div>
							<textarea
								name={`text[${i}]`}
								placeholder='Enter content here'
								value={content.text}
								onChange={onChangeContent}
							/>
						</div>
					))}
				</>
			)}

			{(!page || edit) && (
				<input
					type='button'
					value={`More ${title || 'content'}`}
					onClick={moreContent}
				/>
			)}
		</div>
	)
}

export default Contents
