import React, { useContext, useState, useEffect } from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import { FirebaseContext } from './Firebase'
import { ajax, ajaxList, ajaxCache } from './ajax'
import AuthUserContext from './User/Session/context'
import useReactRouter from './router'
import Authorise from './Authorise'
import { Wargear, WargearList } from './wargear'
import { Character, CharacterList } from './character'
import { Chapter, ChapterList } from './chapter'
import Contents from './Contents'
import deepClone from './js/deepclone'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
	addNew: {
		marginTop: 14
	}
}))

const LinkTo = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
))

const pageSchema = {
	archived: false,
	draft: false
}

const NewButton = ({ category }) => {
	const classes = useStyles()
	return (
		<Authorise group='admin' role='admin'>
			<Button
				variant='outlined'
				to='new/'
				component={LinkTo}
				className={classes.addNew}
				color='primary'
			>
				New {category}
			</Button>
		</Authorise>
	)
}

const Page = props => {
	const firebase = useContext(FirebaseContext)
	const authUser = useContext(AuthUserContext)
	const { history } = useReactRouter()
	const [card, setCard] = useState({})
	const [cardAlt, setCardAlt] = useState({})
	const category = props.match.params.category
		? props.match.params.category.toLowerCase()
		: 'page'
	const page =
		props.match.params.page === 'new'
			? undefined
			: props.match.params.page
			? props.match.params.page
			: 'Home'
	const edit = props.match.params.edit === 'edit'

	if (
		(edit || !page) &&
		(!authUser ||
			!authUser.roles ||
			!authUser.roles['admin'] ||
			!authUser.roles['admin'] == 'admin')
	)
		page ? history.replace(`../../${page}/`) : history.replace('../')

	card.contents = card.contents || [{ text: '', title: '' }]

	cardAlt.contents = cardAlt.contents || [{ text: '', title: '' }]

	useEffect(() => {
		if (page) {
			ajax({
				page: category,
				id: page,
				firebase
			}).then(data => {
				if (data.tags && typeof data.tags === 'object')
					data.tags = data.tags.join(', ')
				setCard(deepClone(data))
				setCardAlt(deepClone(data))
			})
		}
	}, [firebase, category, page])

	const onSubmit = e => {
		e.preventDefault()

		let fb
		let c = deepClone(cardAlt)
		let body = { contents: deepClone(c.contents) }

		const { contents, chapter, docId, contentsId, ...rest } = c

		c = { ...pageSchema, ...rest }

		if (c.tags && c.tags.length) {
			c.tags = c.tags.split(',').map(v => {
				v = v.trim()
				v = v.toLowerCase()
				return v
			})
		}

		if (page) {
			fb = firebase
				.category(category, cardAlt.docId)
				.set(c)
				.then(doc => {
					firebase
						.category(category, cardAlt.docId)
						.collection('contents')
						.doc(cardAlt.contentsId)
						.set(body)
				})
				.then(data => ajaxCache(c.name, cardAlt))
		} else {
			fb = firebase.db
				.collection('pages')
				.add({ ...c, category: category })
				.then(doc => {
					c.docId = doc.id
					cardAlt.docId = doc.id
					return firebase.db
						.doc(doc.path)
						.collection(`contents`)
						.add(body)
						.then(content => {
							c.contentsId = content.id
							cardAlt.contentsId = content.id
						})
				})
		}
		fb.then(data => {
			return ajaxList({
				page: category,
				id: page || card.name,
				firebase,
				nocache: true
			})
		})
			.then(() => {
				setCard(cardAlt)
				page
					? history.push(`../../${c.name}/`)
					: history.push(`../${c.name}`)
			})
			.catch(err => console.log('error', err))
	}

	const onChange = e => {
		setCardAlt({ ...cardAlt, [e.target.name]: e.target.value })
	}

	const onChangeCheckbox = e => {
		setCardAlt({ ...cardAlt, [e.target.name]: e.target.checked })
	}

	const onChangeChapterId = (e, chapter) => {
		setCardAlt({
			...cardAlt,
			[e.target.name]: parseInt(e.target.value),
			chapter
		})
	}

	const onContentChanged = (form, contents) => {
		setCardAlt({ ...cardAlt, [form]: contents })
	}

	const onCancel = () => {
		setCardAlt(deepClone(card))
		history.push('../')
	}

	const altProps = {
		onSubmit,
		page,
		category,
		edit,
		card,
		cardAlt,
		onChange,
		onChangeChapterId,
		onContentChanged,
		onChangeCheckbox,
		onCancel
	}

	return card.name === page || !page ? (
		<Single {...altProps} />
	) : (
		<div>Loading {page}...</div>
	)
}

const Single = props => {
	const {
		onSubmit,
		page,
		category,
		edit,
		onChange,
		onContentChanged,
		onChangeChapterId,
		onChangedCheckbox,
		card,
		cardAlt
	} = props

	return (
		<form onSubmit={onSubmit}>
			<div>
				{page && !edit && (
					<Authorise group='admin' role='admin'>
						<Link
							style={{ float: 'right' }}
							to={`../../../${category}/${page}/edit`}
						>
							Edit
						</Link>
					</Authorise>
				)}
				<h2>
					{page && !edit ? (
						<>{card && card.name}</>
					) : (
						<input
							type='text'
							name='name'
							placeholder='Page title'
							value={card.name}
							onChange={onChange}
						/>
					)}
				</h2>
				<div>
					<Contents
						contents={card.contents}
						contentsAlt={cardAlt.contents}
						page={page}
						edit={edit}
						onContentChanged={onContentChanged}
					/>
				</div>
				{(edit || !page) && (
					<div>
						<input type='submit' value='Submit' name='submit' />
					</div>
				)}
			</div>
		</form>
	)
}

const PageList = props => {
	const firebase = useContext(FirebaseContext)
	const authUser = useContext(AuthUserContext)
	const [cards, setCards] = useState([])
	const [loading, setLoading] = useState(false)
	const category = props.match.params.category

	useEffect(() => {
		setLoading(true)
		ajaxList({ page: category.toLowerCase(), firebase }).then(data => {
			setCards(data)
			setLoading(false)
		})
	}, [firebase, category, authUser])

	return loading ? (
		<div>Loading {category}...</div>
	) : !loading && !cards.length ? (
		<>
			<div>No {category} found</div>
			<NewButton category={category} />
		</>
	) : (
		<>
			<div>
				{cards.map(card => (
					<div>
						<Link to={`../${category}/${card.name}`}>{card.name}</Link>
					</div>
				))}
			</div>
			<NewButton category={category} />
		</>
	)
}

const Pager = () => {
	return (
		<Switch>
			<Route exact path='/' component={Page} />
			<Route exact path='/:category' component={PageList} />
			<Route exact path='/:category/:page' component={Page} />
			<Route exact path='/:category/:page/:edit' component={Page} />
		</Switch>
	)
}

export default Pager
