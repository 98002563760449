import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthUserContext from './User/Session/context'
import SignOut from './User/SignOut'
import Authorise from './Authorise'
import useReactRouter from './router'
import SearchBar from './SearchBar'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import ChapterIcon from '@material-ui/icons/GroupWork'
import CharacterIcon from '@material-ui/icons/Person'
import IconButton from '@material-ui/core/IconButton'
import SwordIcon from '@material-ui/icons/Colorize'
import MenuIcon from '@material-ui/icons/Menu'
import AdminIcon from '@material-ui/icons/Computer'
import LogInIcon from '@material-ui/icons/PlayForWork'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { AppBar, Toolbar } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { FirebaseContext } from './Firebase'

const useStyles = makeStyles(theme => ({
	nav: {
		padding: 0,
		position: 'static'
	},
	list: {
		width: 250
	},
	fullList: {
		width: 'auto'
	},
	menuButton: {
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	navLinks: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4
	}
}))

const LinkTo = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
))

const Nav = () => {
	const [state, setState] = React.useState(false)
	const authUser = useContext(AuthUserContext)
	const firebase = useContext(FirebaseContext)
	const [searchTerm, setSearchTerm] = useState('')
	const { history } = useReactRouter()
	const [categories, setCategories] = useState([])

	useEffect(() => {
		firebase.db
			.collection('settings')
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					if (doc.data().categories) {
						setCategories(doc.data().categories)
					}
				})
			})
	}, [])

	const onSearch = e => {
		e.preventDefault()
		history.push(`../../../../search/${searchTerm}`)
	}

	const toggleDrawer = () => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setState(!state)
	}

	const classes = useStyles()

	const sideList = () => (
		<div
			className={classes.list}
			role='presentation'
			onClick={toggleDrawer()}
			onKeyDown={toggleDrawer()}
		>
			<List>
				<Button component={LinkTo} to='/'>
					<ListItem button key='Home'>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary='Home' />
					</ListItem>
				</Button>
				{categories.map(cat => (
					<Button component={LinkTo} to={`/${cat.name}`}>
						<ListItem button key={cat.name}>
							<ListItemIcon>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText primary={cat.name} />
						</ListItem>
					</Button>
				))}

				<Button component={LinkTo} to='/search'>
					<ListItem button key='Search'>
						<ListItemIcon>
							<SearchIcon />
						</ListItemIcon>
						<ListItemText primary='Search' />
					</ListItem>
				</Button>
			</List>
			<Divider />
			<List>
				{authUser ? (
					<>
						<SignOut>
							<ListItem button key='signout'>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText primary='Sign out' />
							</ListItem>
						</SignOut>
						<Authorise role='admin' group='admin'>
							<Button component={LinkTo} to='/admin/'>
								<ListItem button key='admin'>
									<ListItemIcon>
										<AdminIcon />
									</ListItemIcon>
									<ListItemText primary='Admin' />
								</ListItem>
							</Button>
						</Authorise>
					</>
				) : (
					<Button component={LinkTo} to='/signin/'>
						<ListItem button key='signout'>
							<ListItemIcon>
								<LogInIcon />
							</ListItemIcon>
							<ListItemText primary='Signin' />
						</ListItem>
					</Button>
				)}
			</List>
		</div>
	)

	return (
		<AppBar className={classes.nav} color='main'>
			<Toolbar>
				<Button component={LinkTo} className={`${classes.menuButton}`}>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={toggleDrawer(true)}
						edge='start'
					>
						<MenuIcon />
					</IconButton>
					<Drawer open={state} onClose={toggleDrawer()}>
						{sideList()}
					</Drawer>
				</Button>
				<Button component={LinkTo} to='/' className={`${classes.navLinks}`}>
					Home
				</Button>
				{categories.map(cat => (
					<Button
						component={LinkTo}
						to={`/${cat.name}/`}
						className={classes.navLinks}
					>
						{cat.name}
					</Button>
				))}
				<div style={{ flex: 1, display: 'inline' }} />
				<SearchBar
					onSearch={onSearch}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					className={classes.navLinks}
				/>
				{/* <div style={{ flex: 1, display: 'inline' }} /> */}
				<Authorise role='admin' group='admin'>
					<Button
						component={LinkTo}
						className={classes.navLinks}
						to='/admin/'
					>
						Admin
					</Button>
				</Authorise>
				{authUser ? (
					<SignOut classes={classes} />
				) : (
					<Button
						to='/signin/'
						component={LinkTo}
						className={classes.navLinks}
					>
						Sign In
					</Button>
				)}
			</Toolbar>
		</AppBar>
	)
}

export default Nav
