import React, { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from '../src/Firebase'
import AuthUserContext from './User/Session/context'
import useReactRouter from './router'
import { ajaxList } from './ajax'
import { Link } from '@material-ui/core'
import { tsConstructSignatureDeclaration } from '@babel/types'
import deepClone from './js/deepclone'

const Categories = () => {
	const firebase = useContext(FirebaseContext)
	const [cats, setCats] = useState([])
	const [categoriesId, setCategoriesId] = useState()
	const [newCategoryName, setNewCategoryName] = useState()

	useEffect(() => {
		firebase.db
			.collection('settings')
			.where('type', '==', 'boardSettings')
			.get()
			.then(snapshot => {
				let c = []
				snapshot.forEach(doc => {
					console.log(doc.id)
					console.log(doc.id)
					setCategoriesId(doc.id)
					setCats(doc.data().categories)
				})
			})
	}, [])

	const deleteCategory = (e, name) => {
		e.preventDefault()
		let catClone = deepClone(cats)
		for (let cat in catClone) {
			console.log(catClone[cat].name, name)
			if (catClone[cat].name === name) {
				catClone.splice(cat, 1)
				let categoriesSettings
				firebase.db
					.doc(`settings/${categoriesId}`)
					.get()
					.then(doc => {
						console.log(doc)
						categoriesSettings = {
							...doc.data(),
							categories: catClone
						}
						firebase.db
							.doc(`settings/${categoriesId}`)
							.set(categoriesSettings)
							.then(data => setCats(catClone))
					})
				break
			}
		}
	}

	const addCategory = (e, name) => {
		e.preventDefault()
		let catClone = deepClone(cats)
		firebase.db
			.doc(`settings/${categoriesId}`)
			.get()
			.then(doc => {
				const data = doc.data()
				if (data.categories) {
					catClone.push({
						name: newCategoryName,
						children: []
					})
					const categoriesSettings = {
						...data,
						categories: catClone
					}
					firebase.db
						.doc(`settings/${categoriesId}`)
						.set(categoriesSettings)
						.then(data => setCats(catClone))
				}
			})
	}

	const onCategoryNameChange = e => {
		setNewCategoryName(e.target.value)
	}

	return (
		<div>
			<h2>Categories</h2>
			<div>
				{cats.map(cat => (
					<div key={cat.name}>
						<span>{cat.name}</span>
						<a href='#' onClick={e => deleteCategory(e, cat.name)}>
							Delete
						</a>
					</div>
				))}
			</div>
			<input
				type='text'
				value={newCategoryName}
				onChange={onCategoryNameChange}
			/>
			<input type='button' value='Submit' onClick={addCategory} />
		</div>
	)
}

const Drafts = items => (
	<div>
		<h2>Admin</h2>
		<div>
			{!items.length ? (
				<div>No pages marked as draft</div>
			) : (
				items.map(item => (
					<div>
						{item.name} | {item.category} | {item.draft}{' '}
						<Link to={`../${item.category}/${item.name}/edit`}></Link>
					</div>
				))
			)}
		</div>
	</div>
)

const Dashboard = () => {
	const firebase = useContext(FirebaseContext)
	const [items, setItems] = useState([])

	useEffect(() => {
		firebase.db
			.collection('pages')
			.where('draft', '==', true)
			.get()
			.then(snapshot => {
				let pageInfo = []
				snapshot.forEach(doc => {
					pageInfo.push({ ...doc.data() })
				})
			})
	})

	return (
		<>
			<Categories />
			<Drafts items={items} />
		</>
	)
}

const Admin = () => {
	const firebase = useContext(FirebaseContext)
	const authUser = useContext(AuthUserContext)
	const { history } = useReactRouter()

	useEffect(() => {}, [authUser])

	if (!authUser || !authUser.uid) {
		return <div>Loading...</div>
	} else if (
		!authUser.roles ||
		!authUser.roles.admin ||
		!authUser.roles.admin === 'admin'
	) {
		//history.replace(`/signin`)
	} else {
		return <Dashboard />
	}
}

export default Admin
